<script setup lang="ts">
const {currentPage} = useGlobalStore();
const {background, images, moreLink, subtitle, title} = currentPage.template?.homepage?.gallery;
const carousel = ref(null);

const breakpoints = {
  800: {
    itemsToShow: 4,
    itemsToScroll: 4,
    dir: "rtl",
  },
};
watchEffect(() => {
  if (carousel.value) {
    // carousel.value?.updateSlideSize();

  }
})

</script>
<template>
  <div class="gallery-section section--pad cover"
       :style="`background-image: url('${background?.node?.sourceUrl}.webp')`">
    <span class="text--white text-center title-h2">{{ title }}</span>
    <div class="subtitle text--white text-center">{{ subtitle }}</div>
    <Carousel
        :touch-drag="true"
        :mouse-drag="true"
        class="mx-auto mb-5"
        ref="carousel"
        :items-to-show="2.5"
        :wrap-around="true"
        dir="rtl"
        :items-to-scroll="1"
        :breakpoints="breakpoints"

    >

      <Slide v-for="(item, index) in images" :key="index">
        <a class="gallery-item relative" :href="item.image?.node?.sourceUrl" data-fancybox="gallery">
          <WpImage :img="item.image?.node" :set-webp="true"/>
          <div class="overlay d-flex justify-content-center align-items-center flex-column">

            <div class="plus plus--round mx-auto">
              <svg
                  version="1.1"
                  id="Capa_1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 24 24"
                  style="visibility: visible"
                  xml:space="preserve"
                  class="svg-clean"
              >
                <g>
                  <g>
                    <path
                        d="M23.1,11.1H12.9V0.9C12.9,0.4,12.5,0,12,0c-0.5,0-0.9,0.4-0.9,0.9v10.1H0.9C0.4,11.1,0,11.5,0,12c0,0.5,0.4,0.9,0.9,0.9
			h10.1v10.1c0,0.5,0.4,0.9,0.9,0.9c0.5,0,0.9-0.4,0.9-0.9V12.9h10.1c0.5,0,0.9-0.4,0.9-0.9C24,11.5,23.6,11.1,23.1,11.1z"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
            <span class="red-underline red-underline--short text-white d-block title-h5" v-html="item.title"></span>

          </div>
        </a>
      </Slide>
      <template #addons>
        <Pagination/>
      </template>
    </Carousel>

    <div class="text-center text-white">
      <nuxt-link :to="parseLink(moreLink.url)" class="more-link red-underline red-underline--short">
        {{ moreLink.title }}
      </nuxt-link>
    </div>
  </div>
</template>

<style lang="scss">
:root {
  --vc-pgn-width: 8px;
  --vc-pgn-height: 8px;
  --vc-pgn-margin: 4px;
  --vc-pgn-border-radius: 50%;
}

.carousel__pagination-button {
  &--active {
    &::after {
      opacity: 1 !important;
    }
  }

  &::after {
    background-color: #fff;
    opacity: 0.5;
  }
}

.gallery-section {
  @media screen and (max-width: 800px) {
    .carousel__viewport {
      overflow: visible;
      margin-bottom: 30px;

      .carousel__slide--active {
        transform: scale(1.1);
        z-index: 100;
      }
    }
  }
}
</style>
